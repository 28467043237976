import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import MultiVideoPlayer from '../../../../components/MultiVideoPlayer';
import Icon from '../../../../components/Icon';

const FinalistPresentations2014 = () => (
  <Layout
    title="3M 2014 YSC Finalist Presentations"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">
            3M 2014 YSC Finalist Presentations
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">3M 2014 YSC Finalist Presentations</h1>
            {/* Video Container */}
            <div className="video-container__with-pager multivideo">
              <Link
                to="../3M-2015-YSC-Finalist-Presentations"
                className="previous"
              >
                <Icon name="arrowleftgray" />
              </Link>
              <MultiVideoPlayer
                videos={[
                  {
                    youTubeID: 'kLYmXToraVw',
                    poster: '2014-finalist-thumbnail-final-event.jpg',
                    title: 'At the 2014 Final Event',
                    description:
                      'The 2014 finalists visited 3M’s headquarters in St. Paul to conquer challenges and share their ideas with the panel of judges, including special guest Kari Byron of Discovery Channel’s MythBusters!',
                    className: 'youtube',
                  },
                  {
                    guidSrc: 'd6f66895-dfa7-4d46-b21b-7fb848bf78ae',
                    poster: '2014-finalist-thumbnail-sahil-doshi-winner.jpg',
                    title: 'Meet Sahil, the 2014 Challenge Winner',
                    description:
                      'View 2014 Challenge Finalist Sahil Doshi’s presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'ba1f291b-1cd5-4158-b1c9-4a544ef717a5',
                    poster: '2014-finalist-thumbnail-welcome.jpg',
                    title: 'Welcome and Opening Remarks',
                    description:
                      'Meet the Discovery Education 3M Young Scientist Challenge finalists, mentors and judges at the 2014 final event.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'b38d1391-81aa-4a47-866b-28242b84b46a',
                    poster: '2014-finalist-thumbnail-david-cohen.jpg',
                    title: 'Finalist David Cohen',
                    description:
                      '2014 Challenge Finalist David Cohen’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '601ded09-b023-4882-a610-00a5b1216f68',
                    poster: '2014-finalist-thumbnail-sahil-doshi.jpg',
                    title: 'Finalist Sahil Doshi',
                    description:
                      '2014 Challenge Finalist Sahil Doshi’s presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'c6ea3df6-963a-41f2-8b9f-79fd0933eb1d',
                    poster: '2014-finalist-thumbnail-mythri-ambatipudi.jpg',
                    title: 'Finalist Mythri Ambatipudi',
                    description:
                      '2014 Challenge Finalist Mythri Ambatipudi’s presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '8cf612a1-e1f6-4ae4-8910-3dc7a42b51a7',
                    poster: '2014-finalist-thumbnail-tony-kim.jpg',
                    title: 'Finalist Tony Kim',
                    description:
                      '2014 Challenge Finalist Tony Kim’s presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '11c0432d-4bb1-42ea-af9d-68885c3e5de6',
                    poster: '2014-finalist-thumbnail-andrew-masek.jpg',
                    title: 'Finalist Andrew Masek',
                    description:
                      '2014 3M Young Scientist Challenge Finalist Andrew Masek’s presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '63045556-715a-4e36-9a62-c52145980dec',
                    poster: '2014-finalist-thumbnail-christopher-isozaki.jpg',
                    title: 'Finalist Christopher Isozaki',
                    description:
                      '2014 Challenge Finalist Christopher Isozaki’s presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '501f6a10-f28e-48d1-954f-d4ef88f7b882',
                    poster: '2014-finalist-thumbnail-ana-humphrey.jpg',
                    title: 'Finalist Ana Humphrey',
                    description:
                      '2014 Challenge Finalist Ana Humphrey’s presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '5cff0203-f37d-4865-ad8b-0f9caa5dab13',
                    poster: '2014-finalist-thumbnail-nikita-rafikov.jpg',
                    title: 'Finalist Nikita Rafikov',
                    description:
                      '2014 Challenge Finalist Nikita Rafikov’s presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'eab7bdbb-0d54-457f-a2c4-1f6502630eb9',
                    poster: '2014-finalist-thumbnail-jai-kumar.jpg',
                    title: 'Finalist Jai Kumar',
                    description: '2014 Challenge Jai Kumar’s presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '32f23ee3-713b-42e0-880a-552513e12cf3',
                    poster: '2014-finalist-thumbnail-katherine-wu.jpg',
                    title: 'Finalist Katherine Wu',
                    description:
                      '2014 Challenge Finalist Katherine Wu’s presentation.',
                    className: 'de-vid',
                  },
                ]}
              />
            </div>
            <p className="text-26">
              Hear from the <strong>2014 Top Young Scientist Finalists</strong>{' '}
              as each presents their entry idea for the Annual Young Scientist
              Challenge.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FinalistPresentations2014;
